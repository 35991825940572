import { FirebaseApp, FirebaseOptions, initializeApp } from "firebase/app";
import { Analytics, getAnalytics } from "firebase/analytics";
import { Auth, getAuth, GoogleAuthProvider, onAuthStateChanged, signInWithRedirect, User } from "firebase/auth";
import store from "./store";

class FirebaseAuthWrapper {
    private app!: FirebaseApp;
    private analytics!: Analytics;
    private auth!: Auth;

    private options: FirebaseOptions = {
        apiKey: "AIzaSyCsVUb7T-duoz8erlyzpuSugpMVbxGBrgs",
        authDomain: "website-a302d.firebaseapp.com",
        projectId: "website-a302d",
        storageBucket: "website-a302d.appspot.com",
        messagingSenderId: "904922242556",
        appId: "1:904922242556:web:e0cde3a5cd9cadd41e6679",
        measurementId: "G-J5RE1REY50"
    };

    public initialize(initializeVueApp: () => void) {
        this.app = initializeApp(this.options);
        this.analytics = getAnalytics(this.app);
        this.auth = getAuth();

        onAuthStateChanged(this.auth, (user) => {
            if (!user) {
                this.signInWithRedirect();
                return;
            }

            // Commit user to Vuex first so app always has it available
            store.commit('setUser', user);
            
            initializeVueApp();
          });
    }

    public signInWithRedirect() {
        // todo: Choice of providers
        const provider = new GoogleAuthProvider();
        signInWithRedirect(this.auth, provider);
    }

    public signOut() {
        this.auth.signOut()
            .then(() => {
                console.log('signed out');
                // onAuthStateChanged callback will be invoked
            })
            .catch((error => {
                console.log(`error signing out ${error}`);
            }));
    }

    // getRedirectResult(auth)
    //     .then((result) => {
    //         if (result && result.user) {
    //             // This gives you a Google Access Token. You can use it to access the Google API.
    //             const credential = GoogleAuthProvider.credentialFromResult(result);
    //             const token = credential!.accessToken;
    //             // to be uncommented if you ever need these details
    //         }
    //     })
    //     .catch((error) => {
    //         const errorCode = error.code;
    //         const errorMessage = error.message;
    //         const email = error.email;
    //         // const credential = GoogleAuthProvider.credentialFromError(error);
    //         console.log(`Auth error (${errorCode}) ${errorMessage} for email ${email}`);
    //     });
}

// Somewhat of a singleton
export const firebaseWrapper = new FirebaseAuthWrapper();
