import { getAuth } from 'firebase/auth';
import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import HomeView from '../views/HomeView.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  console.log(`Routing from ${from.fullPath} to ${to.fullPath}`);
  
  // not sure if this is actually needed
  // as the firebase callback will redirect
  // if there is no auth anymore
  if (!getAuth().currentUser) {
    console.log('Tried navigating while unauthenticated!')
    return;
  }

  next();
})

export default router;
