<!-- Note, this is not a secure page -->
<template>
  <!-- <AnimeLines /> -->
  <AnimeNormal />

</template>

<script>
// @ is an alias to /src
// import AnimeLines from '@/components/AnimeLines.vue';
import AnimeNormal from '@/components/AnimeNormal.vue';

export default {
  name: 'HomeView',

  components: { 
    // AnimeLines,
    AnimeNormal
  }
}
</script>

<style>
</style>
