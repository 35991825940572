import App from './App.vue';
import router from './router';
import store, { State } from './store';
import { createApp, InjectionKey } from 'vue';
import { Store } from 'vuex';
import { firebaseWrapper } from './firebaseWrapper';

// Configure Vuex typed state (typescript)
export const key: InjectionKey<Store<State>> = Symbol();

// Start the Firebase app
firebaseWrapper.initialize(() => {
    // Upon successful authentication, start the Vue app
    createApp(App)
        .use(router)
        .use(store, key)
        .mount('#app');
});
