import { User } from 'firebase/auth';
import { createStore } from 'vuex'

export interface State {
  user: User, // potentially map the Firebase user to just what we need
}

export default createStore<State>({
  state: {
    user: <User>{},
  },
  getters: {
    authenticated (state) {
      return !!(state.user)
    }
  },
  mutations: {
    setUser (state, user) {
      state.user = user;
    },
  },
  actions: {
    // login ({ commit }, user) {
    //   commit('setUser', user);
    // },
  },
  modules: {
  }
})
